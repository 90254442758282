import { FC } from 'react';
import styled from 'styled-components';
import Breadcrumb from './Breadcrumb';
import Search from './search/Search';

interface ContentNavProps {
	className?: string;
	crumbs?: string[];
	WAV?: string;
	STN?: string;
	OP?: string;
}

const ContentNavComp: FC<ContentNavProps> = ({ className, crumbs, WAV, STN, OP }) => {
	return (
		<nav
			id="content-nav"
			className={className}
		>
			<Breadcrumb
				crumbs={crumbs}
				WAV={WAV}
				STN={STN}
			/>
		</nav>
	);
};

const ContentNav = styled(ContentNavComp)`
	display: flex;
	justify-content: space-between;
	position: absolute;
`;

export default ContentNav;
