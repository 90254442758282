import { FC } from 'react';
import { Main, Container, Header, Footer } from '../../components/layout';
import hero from '../../assets/hero.png';
import WAVSelector from './WAVSelector';

const Home: FC = () => {
	return (
		<>
			<Main>
				<Header />
				<Container className="home-container">
					<section>
						<left id="home-left">
							<WAVSelector />
						</left>
						<right id="home-right">
							<img
								src={hero}
								id="hero-img"
								alt="Hero Image"
							/>
						</right>
					</section>
				</Container>
			</Main>
			<Footer />
		</>
	);
};

export default Home;
