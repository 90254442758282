import { useState, useEffect, FC } from 'react';
import { FaPlusCircle } from 'react-icons/fa';
import styled from 'styled-components';
import axios from 'axios';

import { IoIosMan } from 'react-icons/io';
import vehicle from '../assets/vehicle.png';
import MasterData from '../data/MasterData';

import AddOP from './addOP';

interface STNOverviewProps {
	className?: string;
	WAV?: string;
	STN?: string;
}

interface OPProps {
	OP: string;
}

const STNOverviewComp: FC<STNOverviewProps> = ({ className, WAV, STN }) => {
	const [OPs, setOPs] = useState<OPProps[]>([]);
	const [showModal, setShowModal] = useState(false);

	const fetchOPs = async () => {
		try {
			const res = await axios.get<OPProps[]>(`/server.php?input=OP&STN=${STN}&WAV=${WAV}`);
			setOPs(res.data);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const OPDisplay = OPs.map((item) => {
		return (
			<div>
				<a
					className="OP-link"
					href={`/OP/?WAV=${WAV}&STN=${STN}&OP=${item.OP}`}
				>
					{item.OP}
				</a>
				<IoIosMan size={220} />
			</div>
		);
	});

	useEffect(() => {
		if (WAV && STN) {
			fetchOPs();
		}
	}, [WAV, STN]);

	return (
		<>
			<section className={className}>
				<left>
					<h1>
						WAV {WAV} - Station {STN}
					</h1>
					<h3>
						Vehicle Prep, Inspection,
						<br />
						A/C Recovery, and Diagnostics
					</h3>

					<div className="d-flex">
						<div className="required-tools">
							<header>
								<h3>
									Required
									<br />
									Tools
								</h3>
								<button
									className="add-button"
									onClick={() => setShowModal(true)}
								>
									<FaPlusCircle size={35} />
								</button>
							</header>

							<div className="tool-list">
								{/* {ToolData &&
									ToolData.map((item) => {
										return <p>{item}</p>;
									})} */}
							</div>
						</div>

						<div className="ppe-requirements">
							<header>
								<h3>
									PPE
									<br />
									Requirements
								</h3>
								<button
									className="add-button"
									onClick={() => setShowModal(true)}
								>
									<FaPlusCircle size={35} />
								</button>
							</header>
							<ul>
								{/* {PPEData &&
									PPEData.map((item) => {
										return <li>{item}</li>;
									})} */}
							</ul>
						</div>
					</div>
				</left>

				<right>
					<header>
						<h1>Select Operator</h1>
						<button
							className="add-button"
							onClick={() => setShowModal(true)}
						>
							<FaPlusCircle size={35} />
						</button>
					</header>

					<div className="operators">{OPs && OPDisplay}</div>

					<img
						src={vehicle}
						alt="vehicle diagram"
					/>
				</right>
			</section>
			<AddOP
				show={showModal}
				hide={() => setShowModal(false)}
			/>
		</>
	);
};

const STNOverview = styled(STNOverviewComp)`
	display: flex;
	justify-content: space-around;
	width: 96vw;
	height: 70vh;
	margin: 6vh auto;
	border-radius: 1rem;
	background: #0c2c4f;
	padding: 20px;
	text-align: center;
	color: #fff;

	& left,
	right {
		padding: 15px;
		& header {
			display: flex;
			justify-content: center;
		}

		& .add-button {
			background: none;
			border: none;
			margin: 0 10px;

			& svg {
				color: #fff;

				&:hover {
					color: lightgray;
					transform: scale(1.05);
				}
			}
		}

		& h1,
		h3 {
			font-weight: bold;
		}
	}

	& left {
		width: 40vw;
	}

	& right {
		width: 35vw;

		& img {
			display: block;
			width: 100%;
			margin: 0 auto;
		}
	}

	.required-tools,
	.ppe-requirements {
		background: #556c84;
		width: 100%;
		height: auto;
		margin: 10px;
		border-radius: 1rem;
		margin-top: 30px;
		padding-bottom: 1.5vw;

		& header {
			display: flex;
			justify-content: space-between;
			border-top-right-radius: 1rem;
			border-top-left-radius: 1rem;
			padding: 10px;
			background: #fff;
			color: var(--primary-blue);
			& svg {
				color: var(--primary-blue);
			}
		}

		& table {
			width: 100%;
		}
	}

	.tool-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;

		& p {
			width: 50%;
		}
	}

	.ppe-requirements li {
		text-align: left;
		list-style: circle;
	}

	.operators {
		display: flex;
		justify-content: center;
		margin-bottom: 25px;
	}

	.OP-link {
		margin: auto;
		position: relative;
		display: block;
		padding: 8px;
		top: 38%;
		background-color: orange;
		width: 2vw;
		height: 2vw;
		border-radius: 50%;
		text-decoration: none;
		color: #fff;
		font-weight: bold;

		&:hover {
			background: var(--green);
		}
	}
`;

export default STNOverview;
