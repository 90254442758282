import { useState, useEffect, FC } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import { FaShuttleVan, FaPlusCircle } from 'react-icons/fa';
import AddWAV from './addWAV';

interface WAVProps {
	WAV: string;
	WAV_enabled: string | number;
}

const WAVOverviewComp: FC<{ className?: string }> = ({ className }) => {
	const [WAVs, setWAVs] = useState<WAVProps[]>([]);
	const [showModal, setShowModal] = useState(false);

	const fetchWAVs = async () => {
		try {
			const res = await axios.get<WAVProps[]>('/server.php?input=WAV');
			setWAVs(res.data);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const WAVDisplay = WAVs.map((item, index) => {
		return (
			<a
				href={`/WAV/?WAV=${item.WAV}`}
				key={index}
			>
				<section style={item.WAV_enabled == 1 ? {} : { background: 'grey' }}>
					<FaShuttleVan size={150} />
					<h3>WAV {item.WAV}</h3>
				</section>
			</a>
		);
	});

	useEffect(() => {
		fetchWAVs();
	}, []);

	return (
		<>
			<div
				id="WAV-overview"
				className={className}
			>
				<div className="WAV-controls">
					<div className="WAV-search">
						<h1>WAV:</h1>
					</div>

					<button onClick={() => setShowModal(true)}>
						<FaPlusCircle size={35} />
					</button>
				</div>
				<div className="WAV-container">{WAVs && WAVDisplay}</div>
			</div>
			<AddWAV
				show={showModal}
				hide={() => setShowModal(false)}
			/>
		</>
	);
};

const WAVOverview = styled(WAVOverviewComp)`
	width: 40vw;
	height: 35vw;
	overflow: auto;
	margin-top: 4vh;

	.WAV-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
		margin-top: 10vh;

		& a {
			text-decoration: none;
		}
	}

	.WAV-controls {
		z-index: 2;
		background: #fff;
		display: flex;
		justify-content: space-between;
		position: fixed;
		width: 40vw;
		height: 10vh;
		padding: 10px;
		border-top-right-radius: 1rem;
		border-top-left-radius: 1rem;

		& button {
			border: none;
			background: none;
		}

		& svg:hover {
			transform: scale(1.05);
			color: var(--primary-blue);
		}
	}

	.WAV-search {
		display: flex;
		& h1 {
			margin: auto 0;
			font-weight: bolder;
		}

		& input {
			border: solid black 1px;
			border-radius: 0.5rem;
			margin: 10px;
			width: 5vw;
		}
	}

	& section {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		width: 16vw;
		height: 12vw;
		background: #ffffff3d;
		margin: 10px;
		border-radius: 1rem;

		& h3 {
			background: #fff;
			font-weight: bold;
			width: 100%;
			text-align: center;
			margin: 0;
			border-bottom-left-radius: 1rem;
			border-bottom-right-radius: 1rem;
			color: var(--primary-blue);
		}

		& svg {
			margin: auto;
			color: #fff;
		}

		&:hover {
			transform: scale(1.02);
			filter: brightness(0.9);
		}
	}
`;

export default WAVOverview;
