import { FC } from 'react';

// packages
import { Main, Container, Header, Footer } from '../../components/layout';

import styled from 'styled-components';
// assets
import { FaUsersCog, FaUsers, FaShuttleVan, FaHardHat } from 'react-icons/fa';
import { IoMdCloseCircle } from 'react-icons/io';
import { BiLineChart } from 'react-icons/bi';

const AdminPage: FC<{ className?: string }> = ({ className }) => {
	return (
		<>
			<Main>
				<Header login />
				<Container>
					<section className={className}>
						<div className="admin-heading">
							<h1>System Administration</h1>
							<h5>Only authorized users can access this portion of the site. This section is used to modify user accounts and set up new pages.</h5>
						</div>

						<div className="admin-buttons">
							<a
								href="/admin/wav-management"
								className="admin-button"
							>
								<FaShuttleVan size={120} />
								<h3>WAV Management</h3>
							</a>
							<a
								href="/admin/station-management"
								className="admin-button"
							>
								<FaHardHat size={120} />
								<h3>Station Management</h3>
							</a>
							<a
								href="/admin/station-logs"
								className="admin-button"
							>
								<BiLineChart size={120} />
								<h3>
									Station
									<br />
									Logs
								</h3>
							</a>
							<a
								href="/admin/user-list"
								className="admin-button"
							>
								<FaUsers size={120} />
								<h3>
									User
									<br />
									List
								</h3>
							</a>
							<a
								href="/admin/user-log"
								className="admin-button"
							>
								<FaUsersCog size={120} />
								<h3>
									User
									<br />
									Log
								</h3>
							</a>
							<a
								href="/admin/failed-logs"
								className="admin-button"
							>
								<IoMdCloseCircle size={120} />
								<h3>Failed Log Attempts</h3>
							</a>
						</div>
					</section>
				</Container>
			</Main>
			<Footer />
		</>
	);
};

const Admin = styled(AdminPage)`
	display: block;
	width: 96vw;
	height: 70vh;
	margin: 6vh auto;
	border-radius: 1rem;
	background: #0c2c4f;
	text-align: center;
	color: #fff;
	padding: 50px;

	& .admin-heading {
		& h1,
		h6 {
			font-weight: 700;
		}
	}

	& .admin-buttons {
		display: flex;
		justify-content: space-around;
		width: 100%;
		margin-top: 12vh;
	}

	& .admin-button {
		width: 12vw;
		height: 12vh;
		text-align: center;
		align-items: start;
		text-decoration: none;
		color: #fff;

		& h3 {
			font-weight: 700;
		}

		& svg {
			margin: 20px;
		}

		&:hover {
			transform: scale(1.03);
			color: lightgray;
		}
	}
`;

export default Admin;
