// packages
import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Main, Container, ContentNav, Header, Footer } from '../../components/layout';

import OPOverview from './OPOverview';

const Operator = () => {
	const [WAV, setWAV] = useState('');
	const [STN, setSTN] = useState('');
	const [OP, setOP] = useState('');

	const location = useLocation();
	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const WAV = queryParams.get('WAV');
		const STN = queryParams.get('STN');
		const OP = queryParams.get('OP');
		if (WAV && STN && OP) {
			setWAV(WAV);
			setSTN(STN);
			setOP(OP);
		}
	}, [WAV, STN, OP]);

	return (
		<>
			<Main>
				<Header />
				<Container>
					<ContentNav
						crumbs={[`WAV ${WAV}`, `Station ${STN}`, `Operator ${OP}`]}
						WAV={WAV}
						STN={STN}
						OP={OP}
					/>
					<section>
						<OPOverview
							WAV={WAV}
							STN={STN}
							OP={OP}
						/>
					</section>
				</Container>
			</Main>
			<Footer />
		</>
	);
};

export default Operator;
