// packages
import { useState, useEffect, FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Main, Container, ContentNav, Header, Footer } from '../../components/layout';

import StationSelector from './STNSelector';

const WAV: FC = () => {
	const [WAV, setWAV] = useState('');
	const location = useLocation();

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const WAVParam = queryParams.get('WAV');
		if (WAVParam) {
			setWAV(WAVParam);
		}
	}, [WAV]);

	return (
		<>
			<Main>
				<Header />
				<Container>
					<ContentNav
						crumbs={[`WAV ${WAV}`]}
						WAV={WAV}
					/>
					<section>
						<StationSelector WAV={WAV} />
					</section>
				</Container>
			</Main>
			<Footer />
		</>
	);
};

export default WAV;
