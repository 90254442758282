import { useState, FC } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import BSModal from 'react-bootstrap/Modal';
import { IoIosUndo, IoIosAddCircle } from 'react-icons/io';

interface addOPProps {
	className?: string;
	show?: boolean;
	hide: () => void;
	WAV?: string;
	STN?: string;
}

const AddOPComp: FC<addOPProps> = ({ className, show, hide, WAV, STN }) => {
	const [OPInput, setOPInput] = useState('');
	const postOP = async () => {
		try {
			const res = await axios.post(`/server.php?input=STN&WAV=${WAV}`, {
				OP: OPInput,
			});
			console.log(res.data);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};
	return (
		// show and hide functions are passed to parent element as props
		<BSModal
			className={className}
			show={show}
			onHide={hide}
			centered
		>
			<BSModal.Header closeButton>
				<BSModal.Title>Add New Station</BSModal.Title>
			</BSModal.Header>
			<BSModal.Body>
				<section id="STN-name">
					<label id="stn-num-label">Station Number:</label>
					<input
						id="stn-num-input"
						type="number"
						min={0}
						value={OPInput}
						onChange={(e) => setOPInput(e.target.value)}
						style={{ width: '3vw' }}
					/>
				</section>

				<section id="STN-OPs">
					<label id="stn-op-label">Number of Operators:</label>
					<input
						id="stn-op-input"
						type="number"
						min="0"
						max="6"
						style={{ width: '3vw' }}
					/>
				</section>

				<section id="STN-desc">
					<label
						id="stn-desc-label"
						style={{ alignSelf: 'flex-start' }}
					>
						Station Description:
					</label>
					<input
						id="stn-desc-input"
						type="text"
						style={{ width: '60%', height: '10vh' }}
					/>
				</section>

				<section id="STN-tools">
					<label id="stn-tool-label">Required Tools:</label>
					<input
						id="stn-tool-input"
						type="text"
					/>
				</section>

				<section id="STN-ppe">
					<label id="stn-ppe-label">PPE Requirements:</label>
					<input
						id="stn-ppe-input"
						type="text"
					/>
				</section>

				<section id="STN-enabled">
					<label>Enabled?</label>
					<input
						id="enabled-input"
						type="checkbox"
						style={{ width: '3vh', height: '3vh' }}
					></input>
				</section>

				<section id="STN-buttons">
					<button
						id="add-STN-btn"
						onClick={postOP}
					>
						<IoIosAddCircle size={30} />
						<p>Add</p>
					</button>
					<button
						id="cancel-STN-btn"
						onClick={() => hide()}
					>
						<IoIosUndo size={30} />
						<p>Cancel</p>
					</button>
				</section>
			</BSModal.Body>
		</BSModal>
	);
};

const AddOP = styled(AddOPComp)`
	input {
		margin: 5px 15px;
		border-radius: 0.5rem;
		height: 5vh;
		padding: 5px;
		font-size: x-large;
		font-weight: bold;
	}

	label {
		font-weight: 700;
		font-size: x-large;
	}
	.modal-dialog {
		width: 55vw;
		max-width: unset;

		.modal-content {
			background: #d0d0d0;
			height: 60vh;
			overflow: auto;

			.modal-header {
				background: var(--primary-blue);
				color: white;
			}

			.modal-body {
				display: flex;
				flex-direction: column;

				& section {
					justify-content: left;
				}

				#STN-buttons {
					display: flex;
					justify-content: center;

					& button {
						display: flex;
						justify-content: space-between;
						padding: 10px;
						margin: 10px;
						border-radius: 1rem;
						width: 6.5vw;
						font-weight: 800;

						& p {
							margin: auto;
							font-size: 17px;
						}
					}

					#add-STN-btn {
						background: var(--primary-blue);
						color: #fff;
					}

					#cancel-STN-btn {
						background: var(--primary-orange);
					}
				}
			}
		}
	}
`;

export default AddOP;
