// packages
import { FC } from 'react';
import styled from 'styled-components';
// assets
import Logo from './logo.png';
import { FaSearch, FaHome, FaQuestionCircle } from 'react-icons/fa';
import { IoMdSettings, IoMdMail } from 'react-icons/io';
import { MdOutlineLogout } from 'react-icons/md';
// nested components
import Helmet from './Helmet'; // for adjusting page title in react
import Tooltip from '../../utilities/Tooltip';

interface HeaderProps {
	className?: string;
	login?: boolean;
}

// header content and logic
const HeaderComp: FC<HeaderProps> = ({ className, login }) => {
	return (
		<>
			<Helmet title="BraunAbility QRL" />
			<header
				className={className}
				onClick={() => console.log('hello trevor')}
			>
				<div className="header-left">
					<a
						className="header-logo mb-md-0 me-md-auto"
						href="/login"
					>
						<img src={Logo} />
					</a>
				</div>

				<div className="header-right">
					{
						// do not render buttons on login page
						!login && (
							<div className="header-buttons">
								<Tooltip
									text="Search"
									direction="down"
									className="d-flex"
								>
									<button>
										<FaSearch size={40} />
									</button>
								</Tooltip>
								<Tooltip
									text="Settings"
									direction="down"
									className="d-flex"
								>
									<a href="/admin">
										<button>
											<IoMdSettings size={45} />
										</button>
									</a>
								</Tooltip>
								<Tooltip
									text="Log-Off"
									direction="down"
									className="d-flex"
								>
									<button>
										<MdOutlineLogout size={50} />
									</button>
								</Tooltip>
								<Tooltip
									text="Contact"
									direction="down"
									className="d-flex"
								>
									<button>
										<IoMdMail size={40} />
									</button>
								</Tooltip>
								<Tooltip
									text="Help"
									direction="down"
									className="d-flex"
								>
									<button>
										<FaQuestionCircle size={40} />
									</button>
								</Tooltip>
								<Tooltip
									text="Home"
									direction="down"
									className="d-flex"
								>
									<button>
										<FaHome size={40} />
									</button>
								</Tooltip>
							</div>
						)
					}
				</div>
			</header>
		</>
	);
};

// header styling
const Header = styled(HeaderComp)`
	z-index: 100; // layer header above anything else
	position: fixed; // lock header at top
	display: flex; // organized left-right
	justify-content: space-between; // header-left and header-right placed on opposite sides
	width: 100%; // width of entire page
	margin: 0;
	padding: 0.8vh 0.4vw;
	background: var(--primary-blue);

	// logo link
	.header-left a {
		color: antiquewhite;
		text-decoration: none;
		margin: auto 0;
	}

	// logo spacing
	.header-left {
		height: 100%;

		& img,
		h1 {
			display: block;
			height: 100%;
			width: 15vw;
			margin: 0;
			color: #000;
		}
	}

	// title spacing
	.header-right {
		display: flex;

		& h2 {
			font-weight: bold;
			margin: auto 1rem;
			color: #fff;
		}

		.header-buttons {
			display: flex;
			& button {
				width: 4vw;
				height: 100%;
				border: none;
				border-radius: 1rem;
				margin: 0 5px;
				background: #ffffff3d;

				& svg {
					color: #fff;
					margin: auto;
				}

				&:hover {
					transform: scale(1.03);
					filter: brightness(0.9);
				}
			}
		}
	}

	@media screen and (max-width: 480px) {
		h2 {
			font-size: medium;
		}

		.header-right {
			margin: 0;
		}
	}
`;

export default Header;

// <Header />
