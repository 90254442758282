// packages
import { FC } from 'react';
import { Main, Container, AdminNav, Header, Footer } from '../../components/layout';

import styled from 'styled-components';
// assets
import { FaSearch, FaFileExport } from 'react-icons/fa';
import { MdOutlineLoop } from 'react-icons/md';
// nested components

const UserLogPage: FC<{ className?: string }> = ({ className }) => {
	return (
		<>
			<Main>
				<Header login />
				<Container>
					<AdminNav crumb="User Log" />
					<section className={className}>
						<div className="admin-heading">
							<h1>User Log</h1>
						</div>

						<div className="admin-table">
							<header className="admin-table-head">
								<div className="control-buttons">
									<button>
										<MdOutlineLoop size={20} />
										<h6>Reset</h6>
									</button>
									<button>
										<FaFileExport size={20} />
										<h6>Export</h6>
									</button>
								</div>

								<div className="search-bar">
									<FaSearch size={30} />
									<input
										type="text"
										placeholder="Search Columns"
									/>
								</div>
							</header>
						</div>
					</section>
				</Container>
			</Main>
			<Footer />
		</>
	);
};

const UserLog = styled(UserLogPage)`
	display: block;
	width: 96vw;
	height: 70vh;
	margin: 6vh auto;
	border-radius: 1rem;
	background: #0c2c4f;
	text-align: center;
	color: #fff;
	padding: 10px;

	& .admin-table {
		background: lightgray;
		margin: 0 auto;
		width: 95%;
		height: 88%;
		border-radius: 1rem;

		& .admin-table-head {
			display: flex !important;
			justify-content: space-between;
			background: #fff;
			height: 8vh;
			display: block;
			color: #000;
			border-top-right-radius: 1rem;
			border-top-left-radius: 1rem;
			padding: 15px;
			border-bottom: #0c2c4f solid 5px;

			& .control-buttons {
				width: 65vw;
				display: flex;
				margin: 0;
				& button {
					display: flex;
					background: #0c2c4f;
					width: fit-content;
					height: 100%;
					color: #fff;
					border-radius: 1rem;
					margin: 0 10px;

					& h6 {
						margin: auto 10px;
					}

					& svg {
						margin: auto 10px;
					}

					&:hover {
						background: #315d8d;
					}
				}
			}

			& .search-bar {
				width: 20vw;
				display: flex;
				& svg {
					margin: auto 10px;
				}
				& input {
					border-radius: 2rem;
					height: 100%;
					width: 100%;
					float: right;
					padding: 10px;
				}
			}
		}
	}

	& .admin-heading {
		& h1,
		h6 {
			font-weight: 700;
		}
	}
`;

export default UserLog;
