import { Route, Routes } from 'react-router-dom';

import Admin from './pages/VI-ADMIN';
import Login from './pages/I-AUTH';

import Home from './pages/II-HOME';
import WAV from './pages/III-WAVs';
import STN from './pages/IV-STNs';
import OP from './pages/V-OPs';
import WAVManagement from './pages/VI-ADMIN/WAVManagement';
import StationManagement from './pages/VI-ADMIN/STNManagement';
import StationLogs from './pages/VI-ADMIN/STNLogs';
import UserList from './pages/VI-ADMIN/UserList';
import UserLog from './pages/VI-ADMIN/UserLog';
import FailedLogs from './pages/VI-ADMIN/FailedLogs';

const Router = () => {
	return (
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/login" element={<Login />} />
			<Route path="/WAV" element={<WAV />} />
			<Route path="/STN" element={<STN />} />
			<Route path="/OP" element={<OP />} />
			<Route path="/admin" element={<Admin />} />
			<Route path="/admin/wav-management" element={<WAVManagement />} />
			<Route path="/admin/station-management" element={<StationManagement />} />
			<Route path="/admin/station-logs" element={<StationLogs />} />
			<Route path="/admin/user-list" element={<UserList />} />
			<Route path="/admin/user-log" element={<UserLog />} />
			<Route path="/admin/failed-logs" element={<FailedLogs />} />
			{/* ------------------------------------- */}

			{/* ------------------------------------- */}
		</Routes>
	);
};

export default Router;
