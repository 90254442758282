import { useState, useEffect, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { FaUser, FaLock } from 'react-icons/fa';
import styled from 'styled-components';
import axios from 'axios';

const LoginFormComp: FC<{ className?: string }> = ({ className }) => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	let navigate = useNavigate();

	const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
		if (email === 'braun' && password === 'ability') {
			navigate('/');
		}
	};

	const handleTest = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		window.alert('hello');

		axios.get('http://localhost:8000/server.php').then((res) => {
			window.alert(res.data);
		});
	};

	return (
		<>
			<Form
				onSubmit={handleLogin}
				className={className}
			>
				<Form.Group className="mb-3">
					<Form.Label>
						<FaUser />
						<b>Email</b>
					</Form.Label>
					<Form.Control
						type="text"
						placeholder="Enter email"
						value={email}
						onChange={(event) => setEmail(event.target.value)}
					/>
				</Form.Group>
				<Form.Group className="mb-3">
					<Form.Label>
						<FaLock />
						<b>Password</b>
					</Form.Label>
					<Form.Control
						type="password"
						placeholder="Enter password"
						value={password}
						onChange={(event) => setPassword(event.target.value)}
					/>
				</Form.Group>

				<Form.Group>
					<Button
						type="submit"
						style={{ display: 'block', margin: '10px auto', background: 'var(--primary-blue)', border: 'none' }}
					>
						Login
					</Button>
					<Button
						variant="secondary"
						type="submit"
						style={{ display: 'block', margin: '10px auto' }}
						onClick={handleTest}
					>
						Forgot my Password
					</Button>
					<Button
						variant="warning"
						type="submit"
						style={{ display: 'block', margin: '10px auto' }}
					>
						<a href="/">Super Secret Login Shortcut</a>
					</Button>
				</Form.Group>
			</Form>
		</>
	);
};

const LoginForm = styled(LoginFormComp)`
	background-color: #b6bbc2;
	padding: 60px;
	border-radius: 1rem;
	width: 30vw;

	.form-label {
		font-weight: bold;
		color: var(--primary-blue);
		display: flex;

		& svg {
			margin: auto 10px;
			height: 100%;
		}
	}

	& button {
	}
`;

export default LoginForm;
