import { useState, FC } from 'react';
import styled from 'styled-components';
import BSModal from 'react-bootstrap/Modal';

import axios from 'axios';

import { IoIosUndo, IoIosAddCircle } from 'react-icons/io';

interface addWAVProps {
	className?: string;
	show?: boolean;
	hide: () => void;
}

const AddWAVComp: FC<addWAVProps> = ({ className, show, hide }) => {
	const [WAVInput, setWAVInput] = useState('');
	const postWAV = async () => {
		try {
			const res = await axios.post('/server.php?input=WAV', {
				WAV: WAVInput,
			});
			window.location.reload();
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};
	return (
		// show and hide functions are passed to parent element as props
		<BSModal
			className={className}
			show={show}
			onHide={hide}
			centered
		>
			<BSModal.Header closeButton>
				<BSModal.Title>Add New WAV</BSModal.Title>
			</BSModal.Header>
			<BSModal.Body>
				<section id="WAV-name">
					<label id="wav-num-label">WAV Number:</label>
					<input
						id="wav-num-input"
						type="number"
						value={WAVInput}
						onChange={(e) => {
							setWAVInput(e.target.value);
						}}
					/>
				</section>

				<section id="WAV-enabled">
					<label>Enabled?</label>
					<input
						id="enabled-input"
						type="checkbox"
					></input>
				</section>

				<section id="WAV-buttons">
					<button
						id="add-wav-btn"
						onClick={postWAV}
					>
						<IoIosAddCircle size={30} />
						<p>Add</p>
					</button>
					<button
						id="cancel-wav-btn"
						onClick={() => hide()}
					>
						<IoIosUndo size={30} />
						<p>Cancel</p>
					</button>
				</section>
			</BSModal.Body>
		</BSModal>
	);
};

const AddWAV = styled(AddWAVComp)`
	input {
		margin: 5px 15px;
	}

	label {
		font-weight: 700;
		font-size: x-large;
	}
	.modal-dialog {
		width: 25vw;
		max-width: unset;

		.modal-content {
			background: #d0d0d0;
			height: 30vh;

			.modal-header {
				background: var(--primary-blue);
				color: white;
			}

			.modal-body {
				display: flex;
				flex-direction: column;
				justify-content: space-around;

				#wav-num-input {
					width: 4vw;
					height: 2.5vw;
					padding: 5px;
					font-size: x-large;
					font-weight: bold;
				}

				#enabled-input {
					width: 2vh;
					height: 2vh;
				}

				#WAV-buttons {
					display: flex;
					justify-content: center;

					& button {
						display: flex;
						justify-content: space-between;
						padding: 10px;
						margin: 10px;
						border-radius: 1rem;
						width: 6.5vw;
						font-weight: 800;

						& p {
							margin: auto;
							font-size: 17px;
						}
					}

					#add-wav-btn {
						background: var(--primary-blue);
						color: #fff;
					}

					#cancel-wav-btn {
						background: var(--primary-orange);
					}
				}
			}
		}
	}
`;

export default AddWAV;
