import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
	// global vars
	:root {
		--primary-blue: #19416c;
		--secondary-blue: #7faedc;
		--red: #ff0000;
		--yellow: #ff0000;
		--primary-orange: #ed7d31;
		--secondary-orange: #ed7d31;
		--green: #70ad47;
		--container-background: white;

		--header-height: 10vh;
		--header-background: #baab7f;

		--footer-height: 8vh;
		--footer-background: none;

		--navbar-width: 6vw;
		--navbar-background: grey;
		
		--breadcrumb-height: 4vh;
		--breadcrumb-background: none;
				
		--content-height: 76vh;
		--content-width: 90vw;
		--content-background: white;
		
		--tooltip-text-color: white;
		--tooltip-background-color: black;
		--tooltip-margin: 30px;
		--tooltip-arrow-size: 6px;
	}

	body {
		font-family: 'Montserrat', sans-serif;
	}

	section {
		display: flex;
		justify-content: space-around;
		align-items: center;
		height: 100%;
	}

	#hero-img {
		transition: all 1.6s ease-in-out;
		width: 50vw;
	}

	#hero-img:hover {
		transform: scale(1.03);
		
	}

	

	// tablesorter pager button effect
    .pager svg:hover {
		filter: drop-shadow(0px 1px 1px black);
		transform: scale(1.1);
		background: #d5d5d5;
		border-radius: 10px;
		cursor: pointer;
	}
`;

export default GlobalStyles;
