import { useState, useEffect, FC } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import { FaPlusCircle, FaHardHat } from 'react-icons/fa';
import AddSTN from './addSTN';

interface STNSelectorProps {
	className?: string;
	WAV?: string;
}

interface STNProps {
	STN: string;
	STN_enabled: string | number;
}

const STNSelectorComp: FC<STNSelectorProps> = ({ className, WAV }) => {
	const [STNs, setSTNs] = useState<STNProps[]>([]);
	const [showModal, setShowModal] = useState(false);
	const [loading, setLoading] = useState(true);

	const fetchSTNs = async () => {
		try {
			const res = await axios.get<STNProps[]>(`/server.php?input=STN&WAV=${WAV}`);
			setSTNs(res.data);
			setLoading(false);
		} catch (error) {
			console.error('Error fetching data:', error);
			setLoading(false);
		}
	};

	// const STNDisplay = STNs.map((item) => {
	// 	return (
	// 		<a href={`/STN/?WAV=${WAV}&STN=${item.STN}`}>
	// 			<section>
	// 				<FaHardHat size={150} />
	// 				<h3>Station {item.STN}</h3>
	// 			</section>
	// 		</a>
	// 	);
	// });

	useEffect(() => {
		if (WAV) {
			fetchSTNs();
		}
	}, [WAV]);

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<>
			<div
				id="station-selector"
				className={className}
			>
				<div className="station-controls">
					<div className="station-search">
						<h1>Station:</h1>
					</div>

					<button onClick={() => setShowModal(true)}>
						<FaPlusCircle size={35} />
					</button>
				</div>
				<div className="station-container">
					{WAV && STNs.length > 0 ? (
						STNs.map((item, index) => (
							<a
								href={`/STN/?WAV=${WAV}&STN=${item.STN}`}
								key={index}
							>
								<section style={item.STN_enabled == 1 ? {} : { background: 'grey' }}>
									<FaHardHat size={150} />
									<h3>Station {item.STN}</h3>
								</section>
							</a>
						))
					) : (
						<div>No stations found.</div>
					)}
				</div>
			</div>
			<AddSTN
				show={showModal}
				hide={() => setShowModal(false)}
				WAV={WAV}
			/>
		</>
	);
};

const STNSelector = styled(STNSelectorComp)`
	width: 80vw;
	height: 35vw;
	overflow: auto;
	margin-top: 10vh;

	.station-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
		margin-top: 10vh;

		& a {
			text-decoration: none;
		}
	}

	.station-controls {
		z-index: 2;
		background: #fff;
		display: flex;
		justify-content: space-between;
		position: fixed;
		width: 80vw;
		height: 10vh;
		padding: 10px;
		border-top-right-radius: 1rem;
		border-top-left-radius: 1rem;

		& button {
			border: none;
			background: none;
		}

		& svg:hover {
			transform: scale(1.05);
			color: var(--primary-blue);
		}
	}

	.station-search {
		display: flex;
		& h1 {
			margin: auto 0;
			font-weight: bolder;
		}

		& input {
			border: solid black 1px;
			border-radius: 0.5rem;
			margin: 10px;
			width: 5vw;
		}
	}

	& section {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		width: 16vw;
		height: 12vw;
		background: #ffffff3d;
		margin: 10px;
		border-radius: 1rem;

		& h3 {
			background: #fff;
			font-weight: bold;
			width: 100%;
			text-align: center;
			margin: 0;
			border-bottom-left-radius: 1rem;
			border-bottom-right-radius: 1rem;
			color: var(--primary-blue);
		}

		& svg {
			margin: auto;
			color: #fff;
		}

		&:hover {
			transform: scale(1.02);
			filter: brightness(0.9);
		}
	}
`;

export default STNSelector;
