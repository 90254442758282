import { useState, useEffect, FC, ReactNode } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Img } from 'react-image';

import BSCarousel from 'react-bootstrap/Carousel';

interface SEQOverviewProps {
	className?: string;
	slides?: [];
	description?: string;
	WAV?: string;
	STN?: string;
	OP?: string;
	SEQ?: number;
}

interface STPProps {
	img_data?: string;
	STP?: string;
	description?: string;
}

const CarouselComponent: FC<SEQOverviewProps> = ({ className, slides, WAV, STN, OP, SEQ }) => {
	const [slideIndex, setSlideIndex] = useState(0);
	const [STPs, setSTPs] = useState<STPProps[]>([]);

	const fetchSTPs = async () => {
		try {
			const res = await axios.get<STPProps[]>(`/server.php?input=STP&WAV=${WAV}&STN=${STN}&OP=${OP}&SEQ=${SEQ}`);

			setSTPs(res.data);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	useEffect(() => {
		setSlideIndex(0); // Reset the selected index to 0 when slides prop changes
	}, [slides]);

	useEffect(() => {
		fetchSTPs();
	}, [WAV, STN, OP, SEQ]);

	return (
		<BSCarousel
			activeIndex={slideIndex}
			onSelect={(index) => setSlideIndex(index)}
			interval={null}
			className={className}
			style={{ width: '55vw' }}
		>
			{STPs &&
				STPs.map((item, index) => {
					return (
						<BSCarousel.Item
							key={index}
							className="slide-wrapper"
						>
							<div className="slide-content">
								<Img
									src={`data:image/png;base64,${item.img_data}`}
									alt="STP Image"
								/>
								<h1>{item.STP}</h1>
								<p>{item.description}</p>
							</div>
							<BSCarousel.Caption></BSCarousel.Caption>
						</BSCarousel.Item>
					);
				})}
		</BSCarousel>
	);
};

const Carousel = styled(CarouselComponent)`
	.carousel.slide {
		position: relative;
		height: 100%;
	}

	.slide-wrapper {
		position: relative;
	}

	.slide-content {
		height: 70vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		background-color: #eee;
		color: #000;
		overflow: auto;
		border-radius: 1rem;

		& img {
			max-width: 48vw;
			max-height: 50vh;
			margin: auto;
		}

		& p {
			font-size: 24px;
			font-weight: 700;
			padding: 25px;
		}

		& .complete-button {
			margin: auto 20px;
			height: 8vh;
			border-radius: 1rem;
			color: #fff;
			font-weight: 700;
			background: var(--green);
		}
	}

	.carousel-control-next,
	.carousel-control-prev {
		background: grey;
		top: 42%;
		width: 5%;
		height: 15%;
	}
`;

export default Carousel;
