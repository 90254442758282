import { useState, useEffect, FC } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import vehicle from '../assets/vehicle.png';
import Carousel from './CarouselV2';

interface OPOverviewProps {
	className?: string;
	WAV?: string;
	STN?: string;
	OP?: string;
}

interface SEQProps {
	SEQ: number;
	top: string;
	left: string;
}

const OPOverviewComp: FC<OPOverviewProps> = ({ className, WAV, STN, OP }) => {
	const [SEQs, setSEQs] = useState<SEQProps[]>([]);
	const [SEQindex, setSEQindex] = useState(1);

	const fetchSEQs = async () => {
		try {
			const res = await axios.get<SEQProps[]>(`/server.php?input=SEQ&WAV=${WAV}&STN=${STN}&OP=${OP}`);
			setSEQs(res.data);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const SEQDisplay = SEQs.map((item, index) => {
		return (
			<a
				className="SEQ-marker"
				style={{ top: `${item.top}%`, left: `${item.left}%` }}
				onClick={() => setSEQindex(item.SEQ)}
			>
				{item.SEQ}
			</a>
		);
	});

	useEffect(() => {
		fetchSEQs();
	}, [WAV, STN, OP]);

	return (
		<section className={className}>
			<left>
				<button>Tools Required</button>
				<button>PPE Required</button>
				<button>Icon Legend</button>

				<div className="seq-map">
					<img src={vehicle} />
					{SEQs && SEQDisplay}
				</div>

				<div className="seq-legend-r">
					<a href="#">#</a>
					<h3>Sequence Incomplete</h3>
				</div>
				<div className="seq-legend-g">
					<a href="#">#</a>
					<h3>Sequence Complete</h3>
				</div>
			</left>

			<right>
				<h1>Sequence: {SEQindex}</h1>
				<Carousel
					WAV={WAV}
					STN={STN}
					OP={OP}
					SEQ={SEQindex}
				/>
			</right>
		</section>
	);
};

const OPOverview = styled(OPOverviewComp)`
	display: flex;
	justify-content: space-between;
	width: 96vw;
	height: 82vh;
	margin: 6vh auto;
	border-radius: 1rem;
	background: #0c2c4f;
	padding: 20px;
	text-align: center;
	color: #fff;

	& left,
	right {
		padding: 15px;
		& h1,
		h3 {
			font-weight: bold;
		}
	}

	& left {
		width: 40vw;
		margin: auto 0;
		& button {
			background: var(--primary-orange);
			color: #fff;
			font-weight: bold;
			border-radius: 1rem;
			border: solid white 3px;
			width: 10vw;
			padding: 15px;
			margin: 5px;

			&:hover {
				transform: scale(1.02);
				filter: brightness(0.9);
			}
		}

		& img {
			width: 100%;
			position: relative;
		}

		.seq-legend-r,
		.seq-legend-g {
			display: flex;
			justify-content: center;
			& a {
				border-radius: 50%;
				border: solid white 3px;
				height: 5vh;
				width: 5vh;
				padding: 10px;
				margin: 10px;
				text-decoration: none;
				color: #fff;
				font-weight: bold;
			}

			& h3 {
				margin: auto 10px;
			}
		}
		.seq-legend-r a {
			background: var(--red);
		}
		.seq-legend-g a {
			background: var(--green);
		}

		.seq-map {
			position: relative;
		}

		.seq-map a {
			position: absolute;
			display: block;
			background: var(--red);
			border-radius: 50%;
			border: solid white 3px;
			height: 3.5vh;
			width: 3.5vh;
			padding: 3px;
			text-decoration: none;
			color: #fff;
			font-weight: bold;
			cursor: pointer;
		}
	}

	& right {
		width: fit-content;
	}
`;

export default OPOverview;
