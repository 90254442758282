import { FC } from 'react';
import styled from 'styled-components';
import BSBreadcrumb from 'react-bootstrap/Breadcrumb';
import { FaHome } from 'react-icons/fa';

interface BreadcrumbProps {
	className?: string;
	crumbs?: string[];
	WAV?: string;
	STN?: string;
}

const BreadcrumbComp: FC<BreadcrumbProps> = ({ className, crumbs = [], WAV, STN }) => {
	switch (crumbs.length) {
		case 1:
			return (
				<BSBreadcrumb className={className}>
					<BSBreadcrumb.Item href="/">
						<FaHome size={40} />
					</BSBreadcrumb.Item>
					<BSBreadcrumb.Item active>{crumbs[0]}</BSBreadcrumb.Item>
				</BSBreadcrumb>
			);
		case 2:
			return (
				<BSBreadcrumb className={className}>
					<BSBreadcrumb.Item href="/">
						<FaHome size={40} />
					</BSBreadcrumb.Item>
					<BSBreadcrumb.Item href={`/WAV/?WAV=${WAV}`}>{crumbs[0]}</BSBreadcrumb.Item>
					<BSBreadcrumb.Item active>{crumbs[1]}</BSBreadcrumb.Item>
				</BSBreadcrumb>
			);
		case 3:
			return (
				<BSBreadcrumb className={className}>
					<BSBreadcrumb.Item href="/">
						<FaHome size={40} />
					</BSBreadcrumb.Item>
					<BSBreadcrumb.Item href={`/WAV/?WAV=${WAV}`}>{crumbs[0]}</BSBreadcrumb.Item>
					<BSBreadcrumb.Item href={`/STN/?WAV=${WAV}&STN=${STN}`}>{crumbs[1]}</BSBreadcrumb.Item>
					<BSBreadcrumb.Item active>{crumbs[2]}</BSBreadcrumb.Item>
				</BSBreadcrumb>
			);
		default:
			return (
				<BSBreadcrumb className={className}>
					<BSBreadcrumb.Item href="/">
						<FaHome size={40} />
					</BSBreadcrumb.Item>
				</BSBreadcrumb>
			);
	}
};

const Breadcrumb = styled(BreadcrumbComp)`
	position: relative;
	height: var(--breadcrumb-height);
	background: var(--breadcrumb-background);

	margin: auto 2.5vw;
	font-size: 20px;

	.breadcrumb-item {
		font-size: 30px;
	}
	.breadcrumb-item.active {
		color: #fff;
	}

	.breadcrumb a {
		margin: 0;
		color: antiquewhite !important;
		text-decoration: none;
	}

	@media screen and (max-width: 480px) {
		font-size: 18px;
	}
`;

export default Breadcrumb;
