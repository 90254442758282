// packages
import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Main, Container, ContentNav, Header, Footer } from '../../components/layout';

import StationOverview from './STNOverview';

const Station = () => {
	const [WAV, setWAV] = useState('');
	const [STN, setSTN] = useState('');

	const location = useLocation();
	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const WAV = queryParams.get('WAV');
		const STN = queryParams.get('STN');
		if (WAV && STN) {
			setWAV(WAV);
			setSTN(STN);
		}
	}, [WAV, STN]);

	return (
		<>
			<Main>
				<Header />
				<Container>
					<ContentNav
						crumbs={[`WAV ${WAV}`, `Station ${STN}`]}
						WAV={WAV}
						STN={STN}
					/>
					<section>
						<StationOverview
							WAV={WAV}
							STN={STN}
						/>
					</section>
				</Container>
			</Main>
			<Footer />
		</>
	);
};

export default Station;
