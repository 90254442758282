//packages
import { FC } from 'react';
import styled from 'styled-components';

// icons
import logo from './its-logo.png';
import poweredBy from './powered-by.png';
import { BsFillTelephoneFill } from 'react-icons/bs';

interface FooterProps {
	className?: string;
}

// footer content and logic
const FooterComp: FC<FooterProps> = ({ className }) => {
	const link = 'https://www.interactivetrainingsystems.com';
	return (
		<footer className={className}>
			<div className="footer-left">
				<a href={link}>
					<img
						className="footer-logo"
						src={poweredBy}
						alt="logo"
					/>
				</a>
			</div>
			<div className="footer-right">
				<h1>QuickResponse&trade; Library</h1>
			</div>
		</footer>
	);
};

// footer styling
const Footer = styled(FooterComp)`
	z-index: 100; // layer footer above anything else
	display: flex; // organized left-right
	justify-content: space-between; // footer-left and footer-right placed on opposite sides
	position: fixed; // stick in place
	bottom: 0; // stick to bottom of page
	width: 100%; // width of entire page
	height: var(--footer-height);
	min-height: 48px;
	background: var(--footer-background);

	// left section
	.footer-left {
		display: flex;
		margin: 0 1rem;

		// powered by text
		.powered-by {
			margin: auto 0;
			padding: 5px;
			font-weight: bold;
		}

		// lower-left logo
		.footer-logo {
			height: 100%;
		}

		& a {
			height: 85%;
		}
	}

	// right section
	.footer-right {
		display: flex;
		margin: 0 20px;
		color: #fff;

		& h1 {
			font-weight: 700;
		}

		// footer right buttons
		& button {
			margin: auto 0.4vw;
			height: 90%;
			width: 3.5vw;

			// footer right buttons hover
			&:hover {
				transform: scale(1.05);
				filter: opacity(0.8);
			}
		}
	}
`;

export default Footer;

// <Footer />
