import { FC } from 'react';
import styled from 'styled-components';
import BSBreadcrumb from 'react-bootstrap/Breadcrumb';
import { FaHome } from 'react-icons/fa';
interface BreadcrumbProps {
	className?: string;
	crumb?: string;
}
const AdminBreadcrumbComp: FC<BreadcrumbProps> = ({ className, crumb }) => {
	return (
		<BSBreadcrumb className={className}>
			<BSBreadcrumb.Item href="/">
				<FaHome size={40} />
			</BSBreadcrumb.Item>
			<BSBreadcrumb.Item href="/admin">System Administration</BSBreadcrumb.Item>
			<BSBreadcrumb.Item active>{crumb}</BSBreadcrumb.Item>
		</BSBreadcrumb>
	);
};

const AdminBreadcrumb = styled(AdminBreadcrumbComp)`
	position: relative;
	height: var(--breadcrumb-height);
	background: var(--breadcrumb-background);

	margin: auto 2.5vw;
	font-size: 20px;

	.breadcrumb-item {
		font-size: 30px;
	}
	.breadcrumb-item.active {
		color: #fff;
	}

	.breadcrumb a {
		margin: 0;
		color: antiquewhite !important;
		text-decoration: none;
	}

	@media screen and (max-width: 480px) {
		font-size: 18px;
	}
`;

export default AdminBreadcrumb;
