// packages
import { FC } from 'react';
import { Main, Container, Header, Footer } from '../../components/layout';
import hero from '../../assets/hero.png';
import LoginForm from './LoginForm';

const Login: FC = () => {
	return (
		<>
			<Main>
				<Header login />
				<Container>
					<section>
						<left id="home-left">
							<LoginForm />
						</left>
						<right id="home-right">
							<img
								src={hero}
								id="hero-img"
								alt=""
							/>
						</right>
					</section>
				</Container>
			</Main>
			<Footer />
		</>
	);
};

export default Login;
